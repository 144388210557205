import { tempRequesting } from "../../../api/tempRequest";

interface UseLogin {
    tempRequest: any
}


export const useLogin = (): UseLogin => {
    const tempRequest = async () => {
        await tempRequesting()
    }


    return { tempRequest }
}
